import moment from 'moment-timezone'

import { httpHelper, measurementHelper } from '@/utils'
import { calculateHumidity } from '@/components/asset-types/cm2-humidity/mixins/humidityCalculation'
import ChartHelper from '@/mixins/ChartHelper'
import UrlHelper from '@/mixins/UrlHelper'

const { VUE_APP_COLOR_ACCENT: colorAccent } = process.env

const temperatureMax = {
    'cm2-humidity': 100,
    'cm2-rope': 60,
    'cm2-simple': 60,
}

export default {
    mixins: [ChartHelper, UrlHelper],
    props: {
        assetType: {
            type: String,
            default: 'cm2-simple',
        },
        id: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            chartData: {},
            chartOptions: {
                chart: {
                    type: 'line',
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                },
                yaxis: [
                    {
                        seriesName: 'humidity',
                        showAlways: true,
                        opposite: true,
                        min: 0,
                        max: 100,
                        tickAmount: 4,
                        decimalsInFloat: 1,
                        title: {
                            text: `${this.$t('humidityGroup')} (${
                                measurementHelper.units.humidity
                            })`,
                        },
                    },
                    {
                        seriesName: 'humidity',
                        show: false,
                        min: 0,
                        max: 100,
                    },
                    {
                        opposite: true,
                        min: 5,
                        max: 30,
                        tickAmount: 5,
                        decimalsInFloat: 1,
                        title: {
                            text: `${this.$t(
                                'shared.measurements.humidity_absolute'
                            )} (${measurementHelper.units.humidity_absolute})`,
                        },
                    },
                    {
                        seriesName: 'temperature',
                        showAlways: true,
                        min: -10,
                        max: temperatureMax[this.assetType],
                        tickAmount: 4,
                        decimalsInFloat: 1,
                        title: {
                            text: `${this.$t('temperatureGroup')} (${
                                measurementHelper.units.temperature
                            })`,
                        },
                    },
                    {
                        seriesName: 'temperature',
                        show: false,
                        tickAmount: 4,
                        min: -10,
                        max: temperatureMax[this.assetType],
                    },
                ],
                tooltip: {
                    shared: true,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
                colors: [
                    colorAccent,
                    '#2E93fA',
                    '#66DA26',
                    '#546E7A',
                    '#E91E63',
                    '#FF9800',
                ],
            },
            settingsAxes: [
                this.$t('temperatureGroup'),
                this.$t('humidityGroup'),
                this.$t('shared.measurements.humidity_absolute'),
            ],
            units: [
                measurementHelper.units.humidity,
                measurementHelper.units.humidity,
                measurementHelper.units.humidity_absolute,
                measurementHelper.units.temperature,
                measurementHelper.units.temperature,
            ],
            yAxisBounds: [],
        }
    },
    computed: {
        chartOptionsComputed() {
            const { yaxis } = this.chartOptions
            const humidityName = this.series[
                yaxis.findIndex(axis => axis.seriesName === 'humidity')
            ]?.name
            const temperatureName = this.series[
                yaxis.findIndex(axis => axis.seriesName === 'temperature')
            ]?.name
            return {
                ...this.chartOptions,
                yaxis: yaxis.map((axis, i) => ({
                    ...axis,
                    ...(axis.seriesName === 'humidity' && {
                        seriesName: humidityName,
                        ...(this.yAxisBounds[1] && {
                            min: this.yAxisBounds[1][0],
                            max: this.yAxisBounds[1][1],
                        }),
                    }),
                    ...(axis.seriesName === 'temperature' && {
                        seriesName: temperatureName,
                        ...(this.yAxisBounds[0] && {
                            min: this.yAxisBounds[0][0],
                            max: this.yAxisBounds[0][1],
                        }),
                    }),
                    ...(i === 2 && {
                        ...(this.yAxisBounds[2] && {
                            min: this.yAxisBounds[2][0],
                            max: this.yAxisBounds[2][1],
                        }),
                    }),
                })),
            }
        },
        series() {
            let series = []
            let dataEntries = Object.entries(this.chartData)
            for (let [key, series_for_depth] of dataEntries) {
                series.push({
                    name: key,
                    data: series_for_depth,
                })
            }
            series.sort(function(a, b) {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            })
            for (let item of series) {
                item.name = this.$t(item.name)
            }
            return series
        },
    },
    watch: {
        customRange() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        handleSettingsChange(...args) {
            this.yAxisBounds = (Array.isArray(args[0]) ? args[0] : [args]).map(
                ([mode, min, max]) => {
                    switch (mode) {
                        case 'auto':
                        case 'custom':
                            return [min, max]
                    }
                }
            )
        },
        async load() {
            this.isLoading = true
            const results = await this.loadData()
            this.setData(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            return results
        },
        setData(results) {
            const chartData = {
                ai1: [],
                humidity: [],
                humidity_absolute: [],
                t1: [],
                temperature: [],
            }

            for (const { sensor_data: sensorData, timestamp } of results) {
                if ('humidity' in sensorData && 'temperature' in sensorData) {
                    chartData.humidity_absolute.push([
                        timestamp,
                        measurementHelper.calculateAbsoluteHumidity(
                            sensorData.humidity,
                            sensorData.temperature
                        ),
                    ])
                }

                for (const key in sensorData) {
                    switch (key) {
                        case 'ai1':
                            chartData[key].push([
                                timestamp,
                                calculateHumidity(
                                    sensorData[key],
                                    this.assetType
                                ),
                            ])
                            break
                        case 'temperature':
                            if (sensorData[key] !== 300) {
                                chartData[key].push([
                                    timestamp,
                                    sensorData[key],
                                ])
                            }
                            break
                        case 'humidity':
                        case 't1':
                            chartData[key].push([timestamp, sensorData[key]])
                            break
                    }
                }
            }

            this.chartData = chartData
        },
    },
}
