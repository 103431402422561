<template>
    <div class="chart-view">
        <div class="l-padded chart-view__datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="l-stack l-gap-2 chart-view__chart">
            <AssetChartsSettings
                :axes="settingsAxes"
                @change="handleSettingsChange"
            />

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <template v-else>
                <AssetChartsStats
                    :colors="chartOptions.colors"
                    :series="series"
                    :units="units"
                />

                <ApexChart
                    ref="chart"
                    height="360px"
                    :options="chartOptionsComputed"
                    :series="series"
                />
            </template>
        </div>
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import AssetChartsSettings from '@/components/AssetChartsSettings'
import AssetChartsStats from '@/components/AssetChartsStats'
import CM2ChartMixin from '@/components/asset-types/cm2-humidity/mixins/CM2ChartMixin'
import DateRangeInput from '@/components/DateRangeInput'

export default {
    name: 'CM2ChartsView',
    components: {
        ApexChart,
        AssetChartsSettings,
        AssetChartsStats,
        DateRangeInput,
        VSpinner,
    },
    mixins: [CM2ChartMixin],
}
</script>

<i18n>
{
    "en": {
        "ai1": "External moisture",
        "bottom": "Bottom",
        "humidity": "Internal humidity relative",
        "humidity_absolute": "Internal humidity absolute",
        "humidityGroup": "Moisture",
        "t1": "External temperature",
        "temperature": "Internal temperature",
        "temperatureGroup": "Temperature",
        "top": "Top"
    },
    "de": {
        "ai1": "Externe Feuchtigkeit",
        "bottom": "Unten",
        "humidity": "Interne relative Luftfeuchtigkeit",
        "humidity_absolute": "Interne absolute Luftfeuchtigkeit",
        "humidityGroup": "Feuchtigkeit",
        "t1": "Externe Temperatur",
        "temperature": "Interne Temperatur",
        "temperatureGroup": "Temperatur",
        "top": "Oben"
    },
    "fr": {
        "ai1": "Humidité externe",
        "bottom": "Bas",
        "humidity": "Humidité interne relative",
        "humidity_absolute": "Humidité interne absolue",
        "humidityGroup": "Humidité",
        "t1": "Température externe",
        "temperature": "Température interne",
        "temperatureGroup": "Température",
        "top": "Haut"
    },
    "it": {
        "ai1": "Umidità esterna",
        "bottom": "Sotto",
        "humidity": "Umidità interna relativa",
        "humidity_absolute": "Umidità interna assoluta",
        "humidityGroup": "Umidità",
        "t1": "Temperatura esterna",
        "temperature": "Temperatura interna",
        "temperatureGroup": "Temperatura",
        "top": "Sopra"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-view {
    display: flex;

    &__chart {
        flex-grow: 100;
        padding: 1rem;
        border-left: $style-border;
        overflow-x: hidden;
    }

    &__datepicker {
        width: 20%;
        min-width: 350px;
    }

    @include respond-to('for-tablet-down') {
        display: block;

        &__chart {
            border: none;
        }

        &__datepicker {
            padding: 1rem;
            width: auto;
            min-width: auto;
            border-bottom: $style-border;
        }
    }
}
</style>
