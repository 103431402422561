<template>
    <div class="asset-charts-stats">
        <div class="form-label">
            <BaseMultiselect
                v-model="option"
                :options="options"
                :custom-label="option => $t(option)"
                :allow-empty="false"
            />
        </div>

        <div class="l-inline l-center l-gap-3">
            <div
                v-for="(item, i) in series"
                :key="i"
                class="l-stack l-center"
                :class="{ hidden: collapsedSeriesIndices[i] }"
            >
                <span class="l-inline l-center-v l-gap-0 t-small">
                    <span
                        v-if="colors[i]"
                        class="asset-charts-stats__color"
                        :style="{ backgroundColor: colors[i] }"
                    />

                    <span>{{ item.name }}</span>
                </span>

                <strong>{{ values[i] }}</strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mean } from 'lodash'

import BaseMultiselect from './redesigned/BaseMultiselect'

const options = ['latest', 'mean', 'increase', 'decrease', 'range', 'minMax']

export default {
    name: 'AssetChartsStats',
    components: {
        BaseMultiselect,
    },
    props: {
        colors: {
            type: Array,
            default: () => [],
        },
        series: {
            type: Array,
            default: () => [],
        },
        units: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            option: options[0],
            options,
        }
    },
    computed: {
        collapsedSeriesIndices() {
            return (
                this.$parent?.collapsedSeriesIndices?.reduce(
                    (acc, cur) => ({ ...acc, [cur]: true }),
                    {}
                ) || {}
            )
        },
        values() {
            switch (this.option) {
                case 'decrease':
                case 'increase':
                    return this.series.map(({ data }, i) => {
                        if (!data.length) {
                            return '–'
                        }
                        const value = data.reduce((acc, cur, i) => {
                            if (i === 0) {
                                return acc
                            }
                            const delta = +data[i - 1][1] - +cur[1]
                            return (this.option === 'decrease' && delta < 0) ||
                                (this.option === 'increase' && delta > 0)
                                ? acc + delta
                                : acc
                        }, 0)
                        return this.formatValue(value, this.units[i], true)
                    })
                case 'minMax':
                    return this.series.map(({ data }, i) => {
                        const values = data.map(([, value]) => +value)
                        const min = this.formatValue(
                            Math.min(...values),
                            this.units[i]
                        )
                        const max = this.formatValue(
                            Math.max(...values),
                            this.units[i]
                        )
                        return `${min} / ${max}`
                    })
                case 'latest':
                    return this.series.map(({ data }, i) => {
                        return this.formatValue(+data[0]?.[1], this.units[i])
                    })
                case 'mean':
                    return this.series.map(({ data }, i) => {
                        const meanValue = mean(data.map(([, value]) => +value))
                        return this.formatValue(meanValue, this.units[i])
                    })
                case 'range':
                    return this.series.map(({ data }, i) => {
                        const values = data.map(([, value]) => +value)
                        const max = Math.max(...values)
                        const min = Math.min(...values)
                        return this.formatValue(max - min, this.units[i])
                    })
                default:
                    return this.series.map(() => '–')
            }
        },
    },
    methods: {
        formatValue(value, unit, hasSignPrefix) {
            if (!isFinite(value) || isNaN(value)) {
                return '–'
            }
            const prefix = hasSignPrefix && value > 0 ? '+' : ''
            const valueFixed = value.toFixed(2)
            return `${prefix}${unit ? `${valueFixed} ${unit}` : valueFixed}`
        },
    },
}
</script>

<i18n>
{
    "en": {
        "decrease": "Decrease",
        "increase": "Increase",
        "latest": "Current value",
        "mean": "Average values",
        "minMax": "Min/max values",
        "range": "Range"
    },
    "de": {
        "decrease": "Abnahme",
        "increase": "Zunahme",
        "latest": "Aktueller Wert",
        "mean": "Mittelwerte",
        "minMax": "Minimal-/Maximalwerte",
        "range": "Bereich"
    },
    "fr": {
        "decrease": "Diminution",
        "increase": "Augmentation",
        "latest": "Dernière valeur",
        "mean": "Valeurs moyennes",
        "minMax": "Valeurs minimales/maximales",
        "range": "Gamme"
    },
    "it": {
        "decrease": "Diminuzione",
        "increase": "Aumento",
        "latest": "Ultimo valore",
        "mean": "Valori medi",
        "minMax": "Valori minimi/massimi",
        "range": "Gamma"
    }
}
</i18n>

<style lang="scss" scoped>
.asset-charts-stats {
    text-align: center;

    & > div {
        &.l-inline {
            flex-wrap: wrap;
        }
    }

    &__color {
        display: inline-block;
        width: 0.75em;
        height: 0.75em;
        border-radius: 50%;
    }

    .t-small {
        white-space: nowrap;
    }

    strong {
        font-size: 1.5em;
    }
}
</style>
