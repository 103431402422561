<template>
    <HiddenForm class="asset-charts-settings" title-class="u-bb">
        <template #title>
            {{ $t('settings') }}
        </template>

        <template #form>
            <div
                v-for="(settingsItem, i) in settings"
                :key="i"
                class="asset-charts-settings__section"
            >
                <p v-if="axes.length > 1" class="form-label">{{ axes[i] }}:</p>

                <div class="l-padded-y l-stack l-gap-1">
                    <BaseMultiselect
                        v-model="settingsItem.scaleMode"
                        :options="scaleModeOptions"
                        :custom-label="option => $t(option)"
                        :allow-empty="false"
                        @input="handleChange"
                    />

                    <div
                        v-if="settingsItem.scaleMode === 'custom'"
                        class="l-inline l-gap-1"
                    >
                        <BaseInput
                            v-model.number="settingsItem.customMin"
                            class="l-full-width"
                            :block="false"
                            :placeholder="$t('min')"
                            type="number"
                            @input="handleChange"
                        />

                        <BaseInput
                            v-model.number="settingsItem.customMax"
                            class="l-full-width"
                            :block="false"
                            :placeholder="$t('max')"
                            type="number"
                            @input="handleChange"
                        />
                    </div>
                </div>
            </div>
        </template>
    </HiddenForm>
</template>

<script>
import { isEqual } from 'lodash'

import BaseInput from '@/components/redesigned/BaseInput'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import HiddenForm from '@/components/HiddenForm'

export default {
    name: 'AssetChartsSettings',
    components: {
        BaseInput,
        BaseMultiselect,
        HiddenForm,
    },
    props: {
        axes: {
            type: Array,
            default: () => [''],
        },
    },
    data() {
        return {
            scaleModeOptions: ['default', 'auto', 'custom'],
            settings: [],
        }
    },
    watch: {
        axes: {
            immediate: true,
            handler(newValue, oldValue) {
                if (isEqual(newValue, oldValue)) {
                    return
                }
                this.settings = (newValue.length ? newValue : ['']).map(
                    newAxis =>
                        this.settings[
                            oldValue?.findIndex(oldAxis => newAxis === oldAxis)
                        ] || {
                            customMax: null,
                            customMin: null,
                            scaleMode: this.scaleModeOptions[0],
                        }
                )
            },
        },
    },
    methods: {
        getEmitArgs(settings) {
            if (settings.scaleMode === 'custom') {
                return [
                    typeof settings.customMin === 'number'
                        ? settings.customMin
                        : undefined,
                    typeof settings.customMax === 'number'
                        ? settings.customMax
                        : undefined,
                ]
            }
            return []
        },
        handleChange() {
            if (this.axes.length > 1) {
                this.$emit(
                    'change',
                    this.settings.map(settings => [
                        settings.scaleMode,
                        ...this.getEmitArgs(settings),
                    ])
                )
            } else {
                this.$emit(
                    'change',
                    this.settings[0].scaleMode,
                    ...this.getEmitArgs(this.settings[0])
                )
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "auto": "Auto scaling",
        "custom": "Custom scaling",
        "default": "Default scaling",
        "max": "Max",
        "min": "Min",
        "settings": "Settings"
    },
    "de": {
        "auto": "Automatische Skalierung",
        "custom": "Benutzerdefinierte Skalierung",
        "default": "Standard-Skalierung",
        "max": "Max",
        "min": "Min",
        "settings": "Einstellungen"
    },
    "fr": {
        "auto": "Mise à l'échelle automatique",
        "custom": "Mise à l'échelle personnalisée",
        "default": "Mise à l'échelle par défaut",
        "max": "Max",
        "min": "Min",
        "settings": "Paramètres"
    },
    "it": {
        "auto": "Scalatura automatica",
        "custom": "Scala personalizzata",
        "default": "Scala predefinita",
        "max": "Max",
        "min": "Min",
        "settings": "Configurazione"
    }
}
</i18n>

<style lang="scss" scoped>
.asset-charts-settings {
    &__section {
        &:first-child {
            .form-label {
                margin-top: 1rem;
            }
        }

        .form-label {
            margin-bottom: -0.5rem;
        }
    }
}
</style>
